// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		pointer-events: none;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(84.17deg, rgb(1, 1, 1) 0%, rgba(16, 17, 19, 0.95) 45%, rgba(48, 51, 57, 0) 85%);
		
			@media only screen and (max-width: 50rem) {
				background: linear-gradient(84.17deg, rgb(1, 1, 1) 0%, rgba(16, 17, 19, 1) 5%, rgba(16, 17, 19, 0.35) 85%);
			}
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover !important;
		}
	`
);
