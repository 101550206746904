// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { Primary, Secondary, White } from './styles';

// Component
// ------

const Inside = ({ label, hasIcon, iconType }) => (
	<>
		{label && <span>{label}</span>}
		{hasIcon && <Icon type={iconType ? iconType : `chevron-right`} />}
	</>
);

const Button = ({
	isPrimary,
	isSecondary,
	isGhost,
	link,
	label,
	hasIcon,
	iconType,
	onClick,
	isWhite,
}) => {
	const prefixBlog = link.model && link.model.name === 'Blog Posts' && `/blog/`;
	const prefixNft =
		link.model && link.model.name === 'All NFT Drops' && `/nft/`;
	const prefixCareers =
		link.model && link.model.name === 'All Careers' && `/careers/`;
	const prefixProjects =
		link.model && link.model.name === 'Projects' && `/projects/`;

	// NOTE • Check which prefix is being used
	const hasPrefix = prefixBlog || prefixNft || prefixCareers || prefixProjects;

	// NOTE • Argument to check to see if prefix is needed
	const urlResult = (hasPrefix ? `/${hasPrefix}` : '') + link + `/`;

	// NOTE • Extra classes for buttons for special styling
	const classes = `${isGhost ? `ghost` : ``} ${hasIcon ? `icon` : ``}`;

	// NOTE • Component Shared Props
	const outerProps = {
		className: classes,
		to: urlResult,
		onClick: onClick,
		preventScrollJump: false,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
		},
	};
	const innerProps = {
		label: label,
		hasIcon: hasIcon,
		iconType: iconType,
	};

	if (isWhite)
		return (
			<White {...outerProps}>
				<Inside {...innerProps} />
			</White>
		);

	// NOTE • If primary prop is passed, or no primary / secondary prop passed
	if ((isPrimary) || (!isPrimary && !isSecondary))
		return (
			<Primary {...outerProps}>
				<Inside {...innerProps} />
			</Primary>
		);

	if (isSecondary && !isWhite)
		return (
			<Secondary className={isWhite ? 'button-white' : ''} {...outerProps}>
				<Inside {...innerProps} />
			</Secondary>
		);
};

export default Button;
