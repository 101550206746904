// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, gradDown, Div, H2, P, Em, grad } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		position: fixed;
		z-index: 12;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		opacity: ${props.isOpen ? 1 : 0};
		pointer-events: ${props.isOpen ? `all` : `none`};
		transition: ${props.theme.ease};

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: ${props.theme.black};
			opacity: 0.8;
		}
	`
);

export const Sweater = styled(Div)(
	(props) => css`
		${gradDown}
		z-index: 2;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		flex: 1;

		${breakup.xlarge`
            top: 0;
            bottom: 0;
            left: 0;
            right: 50%;
        `}
	`
);

export const Close = styled.button(
	(props) => css`
		position: absolute;
		z-index: 20;
		top: 1.2rem;
		right: 1.2rem;

		background: transparent;
		padding: 1.2rem;
		margin: 0;
		transition: ${props.theme.ease};

		${breakup.large`
            top: 2.4rem;
            right: 2.4rem;
        `}

		&:hover {
			opacity: 0.6;
			cursor: pointer;
		}

		svg {
			stroke: ${props.theme.white};
			stroke-width: 2px;
		}
	`
);

export const Content = styled.form(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		width: calc(100% - 4.8rem);

		${breakup.medium`
			width: 75%;
			min-width: 24rem;
			align-items: flex-start;
		`}

		${breakup.large`
			width: 50%;
			min-width: 24rem;
			align-items: flex-start;
		`}
	`
);

export const FieldSet = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 48.6rem;

		margin-bottom: 2.4rem;
		${breakup.large` margin-bottom: 4.8rem; `}

		.field {
			margin-bottom: 2.4rem;
			${breakup.large` margin-bottom: 3.6rem; `}
		}
	`
);

export const Title = styled(H2)(
	(props) => css`
		margin-bottom: 2.4rem;
		${breakup.large` margin-bottom: 4.8rem; `}
	`
);

export const Caption = styled(P)(
	(props) => css`
		margin-bottom: 2.4rem;
		${breakup.large` margin-bottom: 4.8rem; `}
	`
);
export const Options = styled.ul(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		margin-bottom: 2.4rem;
		${breakup.large` margin-bottom: 4.8rem; `}
	`
);

export const Indicator = styled.button(
	(props) => css`
		position: relative;
		width: 3.6rem;
		height: 3.6rem;
		background: ${props.theme.white};
		margin-bottom: 1.2rem;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;

			width: 1.2rem;
			height: 1.2rem;
			background: ${props.theme.bc1};
			transition: ${props.theme.ease};
			opacity: ${props.isChecked === props.value ? 1 : 0};
			transform: translate(-50%, -50%) rotate(${props.isChecked ? 0 : 45}deg)
				scale(${props.isChecked ? 1 : 0.5});
		}
	`
);

export const Option = styled.li(
	(props) => css`
		font-family: ${props.theme.body};
		font-weight: ${props.theme.reg};
		font-size: 1.2rem;
		line-height: 2rem;

		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		padding: 0 1.2rem;
		text-transform: uppercase;

		${breakup.medium`
			padding: 0 2.4rem;
		`}

		${breakup.large`
			flex-flow: row;
			justify-content: center;
			padding: 0 2.4rem;

			&:first-child { padding-left: 0; }
			&:last-child { padding-right: 0; }
		`}

		button {
			cursor: pointer;

			${breakup.large` margin: 0 1.2rem 0 0; `}
		}

		input {
			position: absolute;
			z-index: -1;
			opacity: 0;
			pointer-events: none;
		}
	`
);

export const Field = styled.div(
	(props) => css`
		//-
	`
);

export const Media = styled.div(
	(props) => css`
		position: absolute;
		z-index: 13;
		top: 0;
		left: 50%;
		right: 0;
		bottom: 0;
		background: black;

		display: flex;
		align-items: center;
		justify-content: center;

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;
			margin: auto 0;
		}
	`
);

export const Message = styled(Em)(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: none;

		position: fixed;
		z-index: 200;
		bottom: 0;
		left: 50%;
		width: 100%;
		max-width: calc(100% - 12rem);
		padding: 1.2rem;

		color: ${props.theme.bc1};
		background: ${props.theme.white};

		transform: translateY(100%) translateX(-50%);
		transform-origin: center bottom;
		transition: all 1s ${props.theme.bezzy};

		${breakup.large`
			height: 3.6rem;
			padding: 0 1.2rem;
			left: 50%;
			right: 0;
			width: auto;
			transform: translateY(100%) translateX(0);
		`}
	`
);

export const CloseMessage = styled.button(
	(props) => css`
		background: transparent;
		cursor: pointer;

		svg {
			width: 1.6rem;
			height: 1.6rem;
			stroke: ${props.theme.bc1};
			stroke-width: 2px;
		}
	`
);
