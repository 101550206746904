// Imports
// ------
import styled from 'styled-components';
import { Main } from '@tackl';

// Exports
// ------
export const Fill = styled.span`
    width: 100%;
    height: 100%;
    transition: all .25s linear;
`;

export const Jacket = styled(Main)`
    position: fixed;
    top: 0; left: 0;
    z-index: 9999;
    width: 100%;
    height: ${props => props.showGrid ? `100%` : `0%`};
    pointer-events: none;
    transition: all 1s ${props => props.theme.bezzy};
    

    ${Fill} {
        opacity: ${props => props.altColor ? 0.05 : 0.2};
        background-color: ${props => props.altColor ? 'white' : props.theme.neg};
    }

    > div { height: 100%; }
`;