// Imports
// ------
import React, { useState, useRef } from 'react';
import Icon from '@icons';
import Field from '@parts/Field';
import Button from '@parts/Button';
import Body from '@utils/bodylock';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Video from '@parts/Hero/Home/Video';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { headerState } from '@states/header';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

// Styles
// ------
import {
	Jacket,
	Sweater,
	Content,
	Close,
	Title,
	Caption,
	Options,
	Option,
	Indicator,
	FieldSet,
	Media,
	Message,
	CloseMessage,
} from './styles';

// Component
// ------
const Updates = () => {
	const data = useStaticQuery(graphql`
		query PhoneQuery {
			options: datoCmsOptionsGlobal {
				image: updatesImage {
					alt
					data: gatsbyImageData(
						placeholder: BLURRED
						forceBlurhash: false
						layout: FULL_WIDTH
						backgroundColor: ""
					)
				}
			}

			home: datoCmsHome {
				video {
					url
				}
			}
		}
	`);

	const bp = useBreakpoint();

	const [form, setForm] = useState({
		fName: '',
		email: '',
		interest: 'brand',
	});

	const emailMessage = useRef();

	const [formSuccess, setFormSuccess] = useState(null);

	const handleClose = () => {
		const closeModal = action(() => {
			headerState.modalActive = false;
		});

		closeModal();
		Body.unlock();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		addToMailchimp(form.email, {
			FNAME: form.fName,
			INTEREST: form.interest,
		})
			.then((data) => {
				setFormSuccess(true);
				handleMessageOpen();
			})
			.catch(() => {
				setFormSuccess(false);
			});
	};

	const handleCheckbox = (e) => {
		setForm({
			interest: e.target.value,
			email: form.email,
			fName: form.fName,
		});
	};

	const handleEmail = (e) => {
		setForm({
			email: e.target.value,
			fName: form.fName,
			interest: form.interest,
		});
	};

	const handleName = (e) => {
		setForm({
			fName: e.target.value,
			email: form.email,
			interest: form.interest,
		});
	};

	const handleMessageClose = () => {
		emailMessage.current.style.transform = `translateY(100%)`;
	};

	const handleMessageOpen = () => {
		emailMessage.current.style.transform = `translateY(0%)`;
	};

	return (
		<>
			<Jacket isOpen={headerState.modalActive}>
				<Close onClick={handleClose}>
					<Icon type='close' />
				</Close>

				<Sweater>
					<Content onSubmit={handleSubmit}>
						<Title>Get Updates</Title>
						<Caption>Select one:</Caption>

						<Options>
							<Option>
								<input type='checkbox' checked={form.brand} readOnly />
								<Indicator
									type='button'
									onClick={handleCheckbox}
									value='brand'
									isChecked={form.interest}
								/>
								Brand
							</Option>

							<Option>
								<input type='checkbox' checked={form.studio} readOnly />
								<Indicator
									type='button'
									onClick={handleCheckbox}
									value='studio'
									isChecked={form.interest}
								/>
								Studio
							</Option>

							<Option>
								<input type='checkbox' checked={form.creator} readOnly />
								<Indicator
									type='button'
									onClick={handleCheckbox}
									value='creator'
									isChecked={form.interest}
								/>
								Creator
							</Option>
						</Options>

						<FieldSet>
							<Field
								placeholder='Full Name'
								className='field'
								value={form.fName || ''}
								onChange={handleName}
							/>
							<Field
								placeholder='Email *'
								className='field'
								value={form.email || ''}
								onChange={handleEmail}
							/>
						</FieldSet>

						<Button
							isGhost
							hasIcon
							onClick={handleSubmit}
							label='Submit'
							link='#'
						/>
					</Content>
				</Sweater>

				{bp.large ? null : (
					<Media>
						{/* <Video url={data.home.video.url} /> */}
						<StaticImage src="../../images/trainer.png" alt="Trainer sneaker render" sizes="50vw" loading="eager" />
					</Media>
				)}
			</Jacket>

			<Message
				ref={emailMessage}
				success={formSuccess === true}
				error={formSuccess === false}
				sending={formSuccess === null}>
				{formSuccess === null && `Sending...`}
				{formSuccess === false && `Form failed`}
				{formSuccess === true && `Form Success`}

				<CloseMessage type='button' onClick={handleMessageClose}>
					<Icon type='close' />
				</CloseMessage>
			</Message>
		</>
	);
};

export default observer(Updates);
