// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div } from '@tackl';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// -------
export const Jacket = styled(Div)(
	(props) => css`
		width: 100%;
		background: ${props.needsBg ? props.theme.black : `transparent`};
		transition: ${props.theme.ease};

		${breakup.medium` padding: 2.4rem  0; `}

		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 130rem;
			margin: 0 auto;
			width: 94%;

			${breakup.xhuge`
                max-width: 165rem;
            `}
		}
	`
);

export const Home = styled(TransitionLink)(
	(props) => css`
		display: inline-flex;
		transition: ${props.theme.ease};

		${breakup.large` min-width: 16rem; `}

		&:hover {
			opacity: 0.6;
		}
	`
);

const sharedTextStyles = (props) => css`
	font-family: ${props.theme.body};
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 1;
	color: ${props.theme.white};
`;

const pageLinkStyle = (props) => css`
	${sharedTextStyles}

	transition: ${props.theme.ease};
	padding: 1.2rem 2vw;

	${breakup.large` padding: 1.2rem 2.4rem; `}
`;

export const PageLink = styled(TransitionLink)(
	(props) => css`
		${pageLinkStyle}

		&:hover {
			opacity: 0.6;
			cursor: pointer;
		}
	`
);

export const FakePageLink = styled.span(
	(props) => css`
		${pageLinkStyle}
		color: rgba(255,255,255,0.2);
		cursor: default;

		&:after {
			content: "In Progress";
			position: absolute;
			top: -0.2rem;
			left: calc(100% - 1rem);
			transform: translateX(-75%);
			color: #6C53FF;
			font-size: 1rem;
			font-weight: 300;
			text-transform: initial;
			width: max-content;
			transition: ${props.theme.ease};
			opacity: 0;
		}

		&:hover::after {
			opacity: 1;
		}
	`
);

export const Sublink = styled(TransitionLink)(
	(props) => css`
		${pageLinkStyle}
		text-transform: none;

		padding: 1.2rem;
		width: 12rem;
		background: ${props.theme.bc3};
		transition: ${props.theme.ease};

		&:hover {
			background: ${props.theme.bc1};
		}
	`
);

export const PageList = styled.ul(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transition: ${props.theme.ease};

		> li {
			position: relative;
			display: flex;
			margin: 0 1.2rem 0 0;
			transition: ${props.theme.ease};

			${breakup.large` margin: 0 2.4rem 0 0; `}

			&:last-child {
				margin: 0 0 0 2.4rem;
			}

			&:hover > ul {
				opacity: 1;
				pointer-events: auto;
			}

			// NOTE • Submenu
			> ul {
				position: absolute;
				top: 100%;
				left: 2.4rem;

				display: flex;
				flex-flow: column;
				justify-content: flex-end;
				align-items: flex-start;
				width: max-content;

				opacity: 0;
				pointer-events: none;
				transition: ${props.theme.ease};

				> li {
					display: inline-flex;
				}
			}
		}
	`
);

export const UpdatesButton = styled.button(
	(props) => css`
		${sharedTextStyles}
		position: relative;
		background: transparent;
		padding: 1.1rem 4rem;
		cursor: pointer;
		transition: ${props.theme.ease};
		border-radius: 0.5rem;
		overflow: hidden;
		background: #00000080;
		color: ${props.theme.white};

		&:hover {
			color: ${props.theme.white};

			&:before {
				transform: translateX(100%);
			}
			&:after {
				opacity: 1;
			}

			span {
				text-shadow: 1px 12px 5px rgba(0, 0, 0, 0.12);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: ${props.theme.ease};
		}

		&:before {
			z-index: 1;
			background: linear-gradient(to right, transparent, white, transparent);
			left: 0;
			right: auto;
			width: 100%;
			transform: translateX(-100%);
		}
	`
);
