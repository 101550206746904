// Imports
// ------

// Exports
// ------
class Body {
	lock() {
		const bodyTag = document.querySelector('body');
		const isSSR = typeof window === 'undefined';

		if (!isSSR) {
			this.scrollY = window.scrollY;
			bodyTag.style.position = 'fixed';
			bodyTag.style.overflow = 'hidden';
			bodyTag.style.top = `${-this.scrollY}px`;
			bodyTag.style.left = `0px`;
			bodyTag.style.right = `0px`;
		}
	}

	unlock() {
		const bodyTag = document.querySelector('body');
		const isSSR = typeof window === 'undefined';

		if (!isSSR) {
			bodyTag.style.position = 'initial';
			bodyTag.style.overflow = 'initial';
			window.scrollTo(0, this.scrollY);
		}
	}
}

const inst = new Body();
export default inst;
