// Imports
// ------
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '@parts/Header';
import Updates from '@parts/Updates';
import Grid from '@parts/Grid';

// Styles
// ------
import '@styles/global.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme, GlobalStyle } from '@theme';

// Component
// ------
function Layout({ children }) {
	useEffect(() => {
		document.documentElement.style.setProperty(
			'--vh',
			`${window.innerHeight * 0.01}px`
		);
	}, []);

	return (
		<>
			<ThemeProvider theme={lightTheme}>
				<Header />
				<Updates />
				<Grid />
				{children}
			</ThemeProvider>

			<GlobalStyle theme={lightTheme} />
		</>
	);
}

export default Layout;

Layout.defaultProps = {
	children: null,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
