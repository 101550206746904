module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(max-width: 370px)","smedium":"(max-width: 768px)","medium":"(max-width: 1024px)","large":"(max-width: 1200px)","xlarge":"(max-width: 1440px)","xxlarge":"(max-width: 1600px)","huge":"(max-width: 2000px)","uber":"(max-width: none)","portrait":"(orientation: portrait)","horizontal":"(orientation: horizontal)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MX–R","short_name":"MX–R","start_url":"/","background_color":"#121212","theme_color":"#D8CCC2","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b7eff3ca512783a3c45ba3db78ede5c"},
    }]
