// **********************************************
// Waffl Grid 1.0
// **********************************************

// Imports
// ------
import offset from './offset';
import ordering from './ordering';
import collapse from './collapse';
import mobilePadding from './mobilePadding';
import visibility from './visibility';
import breakpoints from './breakpoints';
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { pull, pullOnly, push, pushOnly } from './pushPull';

// Exports
// ------
export const Column = styled.div(
	(props) => css`
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		${props.maxHeight &&
		css`
			height: 100%;
	`}

    /* Ordering */
    ${ordering}

    /* Breakpoints */
    ${breakpoints}

    /* Push Columns */
    ${push}
    ${pushOnly}

    /* Pull Columns */
    ${pull}
    ${pullOnly}

    /* Offset Columns */
    ${offset}

    /* Visibility */
    ${visibility}
	`
);

export const Row = styled.div((props) => css`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	flex-flow: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	margin: 0 auto;

	/* Want to get rid of that pesky margin? */
	${collapse}

	${props.maxHeight &&
		css`
			height: 100%;
		`}

	${props.maxHeightDesktop &&
		css`
			${breakup.large` height: 100%; `}
		`}

	/* Want the grid full width of the screen? */
	${props.isExpanded &&
		css`
			max-width: 100%;
		`}

	/* Vertically position top content */
	${props.isTop &&
		css`
			align-items: flex-start;
			height: 100%;
		`}

	/* Vertically Center content */
	${props.isCenter &&
		css`
			align-items: center;
			height: 100%;
		`}
	/* Vertically position bottom content */
	${props.isBottom &&
		css`
			align-items: flex-end;
			height: 100%;
		`}

	/* Align content to bottom */
	${props.isBottom &&
		css`
			align-items: flex-end;
		`}

	/* Reverse ordering of content */
	${props.isReversed &&
		css`
			flex-direction: row-reverse;
		`}

	/* Make content equalised */
	${props.isEqual &&
		css`
			align-items: stretch;

			> div {
				height: 100%;
			}
		`}

	/* Want a vertical grid? */
	${props.vertical &&
		css`
			flex-direction: column;
	`}

	max-width: 130rem;
	width: 94%;

	${breakup.xhuge`
		max-width: 165rem;
	`}
	`
);
