// Imports
// ------
import styled, { css } from 'styled-components';
import { Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Em)(
	(props) => css`
		color: ${props.theme.white};
		text-transform: initial;
		font-size: 1.4rem;
		font-weight: ${props.theme.light};
	`
);
