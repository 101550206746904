// Imports
// ------
import styled, { css } from 'styled-components';
import { Section, grad, breakup } from '@tackl';
import { Link } from 'gatsby';
import { emStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: fixed;
		z-index: 11;
		left: 0;
		right: 0;

		> div {
			display: ${props.isVisible ? `flex` : `none`};
			justify-content: center;
		}

		${breakup.xxlarge`
			width: 100%;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		`}
	`
);

const sharedButtonStyles = (props) => css`
	${grad}

	position: relative;
	cursor: pointer;

	display: flex;
	justify-content: center;
	width: 100%;
	padding: 1.65rem 2.4rem;

	${breakup.large`
		padding: 1.75rem 2.4rem;
	`}

	&::before {
		content: '';
		width: 100%;
		height: 2rem;
		${grad}
		position: absolute;
		top: 0;
		left: 0;
		top: -1.7rem;
		left: 0;
		z-index: 15;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: ${props.theme.ease};
	}

	&:after {
		z-index: 2;
		background: ${props.theme.bc1};
		opacity: 0;
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}

	span {
		${emStyles}
		position: relative;
		z-index: 3;
		font-size: 1.2rem !important;
		text-transform: none;
		letter-spacing: 1px;
		font-weight: ${props.theme.bold};

		${breakup.large`
			font-size: 1.8rem !important;
		`}

		+ span {
			margin: 0 0 0 0.6rem;
			font-weight: ${props.theme.reg};
		}
	}
`;

export const ButtonInt = styled(Link)(
	(props) => css`
		${sharedButtonStyles}
	`
);

export const ButtonExt = styled.a(
	(props) => css`
		${sharedButtonStyles}
	`
);
