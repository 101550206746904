// Imports
// ---------
import styled, { css } from 'styled-components';
import { containerStyles } from './containerStyles';
import { imageStyles } from './imageStyles';
import {
	h1Styles,
	h2Styles,
	h3Styles,
	h4Styles,
	h5Styles,
	pStyles,
	spanStyles,
	emStyles,
	labelStyles,
	gradientTextStyles,
} from './typographyStyles';
import { listStyles, listItemStyles } from './listStyles';
import { breakpointUp, breakpointDown, breakpointOnly } from './breakpoints';

// --------------
// 1. Containers
// --------------
export const Section = styled.section`
	${containerStyles}
`;
export const Div = styled.div`
	${containerStyles}
`;
export const Main = styled.main`
	${containerStyles}
`;
export const Nav = styled.nav`
	${containerStyles}
`;
export const Article = styled.article`
	${containerStyles}
`;
export const Header = styled.header`
	${containerStyles}
`;
export const Footer = styled.footer`
	${containerStyles}
`;
export const Wrapper = styled.div`
	max-width: 170rem;
    margin: 0 auto;

	&.has-columns {
		max-width: 172.3rem;
	}
`;

// --------------
// 2. Images
// --------------
export const Img = styled.img`
	${imageStyles}
`;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1`
	${h1Styles}
`;
export const H2 = styled.h2`
	${h2Styles}
`;
export const H3 = styled.h3`
	${h3Styles}
`;
export const H4 = styled.h4`
	${h4Styles}
`;
export const H5 = styled.h5`
	${h5Styles}
`;
export const P = styled.p`
	${pStyles}
`;
export const Em = styled.em`
	${emStyles}
`;
export const Span = styled.span`
	${spanStyles}
`;
export const Label = styled.p`
	${labelStyles}
`;
export const GradientText = styled.span`
	${gradientTextStyles}
`;

// --------------
// 4. List
// --------------
export const List = styled.ul`
	${listStyles}
`;
export const ListItem = styled.li`
	${listItemStyles}
`;

// --------------
// 4. Breakpoints
// --------------
export const breakup = breakpointUp;
export const breakdown = breakpointDown;
export const breakonly = breakpointOnly;

// --------------
// 5. Gradients
// --------------
export const grad = (props) => css`
	background: linear-gradient(
		90deg,
		${props.theme.bc1} 0%,
		${props.theme.bc2} 100%
	);
`;

export const gradDown = (props) => css`
	background: linear-gradient(
		180deg,
		${props.theme.bc1} 0%,
		${props.theme.bc2} 100%
	);
`;

export const gradUp = (props) => css`
	background: linear-gradient(
		0deg,
		${props.theme.bc1} 0%,
		${props.theme.bc2} 100%
	);
`;

export const gradLeft = (props) => css`
	background: linear-gradient(
		-90deg,
		${props.theme.bc1} 0%,
		${props.theme.bc2} 100%
	);
`;

// --------------
// 6. Content Container (WYSIWYG)
// --------------
export const WYSIWYG = styled.div(
	(props) => css`
		* {
			&:last-child {
				margin: 0;
			}
		}

		h2,
		h3,
		h4,
		h5 {
			margin-bottom: 2.4rem;

			${breakup.large`
                margin-bottom: 4.8rem;
            `}

			${breakup.xxlarge`
                margin-bottom: 6rem;
            `}
		}

		h2 {
			${h2Styles}
		}

		h3 {
			${h3Styles}
		}

		h4 {
			${h4Styles}
		}

		h5 {
			${h5Styles}
		}

		p {
			${pStyles}
			margin-bottom: 2.4rem;

			${breakup.large`
                margin-bottom: 4.8rem;
            `}

			${breakup.xxlarge`
                margin-bottom: 6rem;
            `}
		}

		a {
			display: inline-block;
			position: relative;
			overflow: hidden;
			padding-bottom: 2px;
			transition: ${props.theme.ease};

			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;

				width: 100%;
				height: 1px;
				transition: ${props.theme.ease};
			}

			&:before {
				transform: translateX(0%);
				background: ${props.theme.white};
			}

			&:after {
				transform: translateX(100%);
				background: ${props.theme.bc1};
			}

			&:hover {
				color: ${props.theme.bc1};

				&:before {
					transform: translateX(-100%);
				}

				&:after {
					transform: translateX(0%);
				}
			}
		}

		blockquote {
			font-style: italic;
			margin-bottom: 2.4rem;

			${breakup.large`
                margin-bottom: 4.8rem;
            `}

			${breakup.xxlarge`
                margin-bottom: 6rem;
            `}

			p {
				${h5Styles}
				font-weight: ${props.theme.semi};
			}
		}

		strong {
			font-weight: 500;
		}

		ol {
			li {
				&:before {
					content: counter(li, decimal-leading-zero) '.';
				}
				p { display: inline-block; }
			}
		}

		ul {
			li {
				&:before {
					content: '•';
				}
				p { display: inline-block; }
			}
		}

		ol,
		ul {
			list-style: none;
			counter-reset: li;
			margin-bottom: 2.4rem;

			${breakup.large`
                margin-bottom: 4.8rem;
            `}

			${breakup.xxlarge`
                margin-bottom: 6rem;
            `}

			li {
				counter-increment: li;
				${pStyles}

				&:before {
					display: inline-block;
					font-weight: ${props.theme.bold};
					margin-right: 1.2rem;
					color: ${props.theme.white};
					width: 2.4rem;
				}
			}
		}
	`
);
