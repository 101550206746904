// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		input {
			${pStyles}
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background: transparent;
			border: none;
			color: ${props.theme.black};

			text-indent: ${props.isActive ? `1.2rem` : `0rem`};

			&::placeholder {
				${pStyles}
				color: transparent;
				transition: ${props.theme.ease};
			}

			&:focus,
			&:active {
				outline: none;
				text-indent: 1.2rem;

				&::placeholder {
					color: ${props.theme.black};
				}

				+ span {
					&:after {
						transform: scaleY(1);
					}
				}
			}
		}

		span {
			${pStyles}
			width: 100%;

			position: relative;
			z-index: 1;
			padding: 1.2rem 0;
			color: ${props.theme.white};
			transition: ${props.theme.ease};
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				transform: scaleY(${props.isActive ? 1 : 0.03});
				transform-origin: center bottom;
				background: ${props.theme.white};
				transition: ${props.theme.ease};
			}
		}
	`
);
