// Imports
// ------
import React, { useRef, useState, useEffect } from 'react';
import Notification from './Notification';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticQuery, graphql } from 'gatsby';
import { observer } from 'mobx-react';
import { headerState } from '@states/header';

// Styles
// ------
import { Jacket, MobileJacket } from './styles';

// Component
// ------
const Header = () => {
	const bp = useBreakpoint();
	const notification = useRef();
	const [notifySize, setNotifySize] = useState(0);

	useEffect(() => {
		const el = notification;
		setNotifySize(el.current !== undefined ? el.current.offsetHeight : 0);

		window.addEventListener("resize", (event) => {
			setNotifySize(el.current !== undefined ? el.current.offsetHeight : 0);
		});
	}, [headerState.notifyActive]);

	return (
		<StaticQuery
			query={graphql`
				query Header {
					global: datoCmsOptionsGlobal {
						isActive: notificationBanner
						text: notificationText
						link: notificationLink
						isExternal: notificationExternalLink
						nfts: nftsEnabled
					}
				}
			`}
			render={(d) => (
				<>
					{d.global.isActive && (
						<Notification
							forwardRef={notification}
							link={d.global.link}
							label={d.global.text}
							isExternal={d.global.isExternal}
						/>
					)}

					{bp.smedium ? (
						<MobileJacket
							notificationSize={notifySize}
							notifyActive={headerState.notifyActive}>
							<Mobile notify={notifySize} nftEnabled={d.global.nfts} />
						</MobileJacket>
					) : (
						<Jacket notify={notifySize} notifyActive={headerState.notifyActive}>
							<Desktop nftEnabled={d.global.nfts} />
						</Jacket>
					)}
				</>
			)}
		/>
	);
};

export default observer(Header);
