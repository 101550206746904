// Imports
// ------
import styled, { css } from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';
import { grad } from '@tackl';
import { breakup } from '@tackl';

// Exports
// ------

const sharedStyles = (props) => css`
	${grad}
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;

	width: max-content;
	padding: 0 3.5rem;
	transition: ${props.theme.ease};
	overflow: hidden;
	border-radius: 0.5rem;

	${breakup.medium`
		padding: 0 4.5rem;
	`}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: ${props.theme.ease};
	}

	&:before {
		z-index: 1;
		background: linear-gradient(to right, transparent, white, transparent);
		left: 0;
		right: auto;
		width: 100%;
		transform: translateX(-100%);
	}

	&:after {
		z-index: 2;
		background: ${props.theme.bc1};
		opacity: 0;
	}

	&:hover {

		&:before {
			transform: translateX(100%);
		}
		&:after {
			opacity: 1;
		}

		span {
			text-shadow: 1px 12px 5px rgba(0, 0, 0, 0.12);
		}
	}


	// NOTE • Label
	span {
		display: inline-block;
		position: relative;
		z-index: 3;
		font-family: ${props.theme.body};
		font-weight: ${props.theme.med};
		font-size: 1.4rem;
		line-height: 1;
		letter-spacing: 1px;
		transition: ${props.theme.ease};

		${breakup.medium`
			font-size: 1.8rem;
		`}
	}

	// NOTE • Icon
	svg {
		display: inline-block;
		position: relative;
		z-index: 3;
		margin: 0 0 0 2.4rem;
		width: 1.6rem;
		height: 1.6rem;
		fill: ${props.theme.white};
		transition: ${props.theme.ease};
	}

	// NOTE • If ghost class is applied
	&.ghost {
		background: transparent;
		border: 1px solid ${props.theme.white};

		&:hover {
			&:after {
				background: ${props.theme.white};
			}

			span {
				color: ${props.theme.bc1};
			}

			svg {
				fill: ${props.theme.bc1};
			}
		}
	}

	&.icon {
		padding: 0 2.4rem 0;
	}
`;

export const Primary = styled(TransitionLink)(
	(props) => css`
		${sharedStyles}

		height: 4.35rem;

		${breakup.medium`
			height: 5rem;
		`}
	`
);

export const White = styled(TransitionLink)(
	(props) => css`
		${sharedStyles}

		background: #fff !important;
		height: 4rem;
		color: #3116ce !important;
		border-radius: 1rem;
		transition: filter 0.2s;
		padding: 0 3rem;

		${breakup.medium`
			padding: 0 4rem;
			height: 4rem;
		`}

		&::after {
			background: #fff !important; 
		}

		&::before {
			background: linear-gradient(to right,transparent,#ada8ca,transparent) !important;
		}
	`
);

export const WhiteButton = styled.button(
	(props) => css`
		${sharedStyles}

		background: #fff !important;
		height: 4rem;
		color: #3116ce !important;
		border-radius: 1rem;
		transition: filter 0.2s;
		padding: 0 3rem;
		font-family: 'Kanit','Inter', sans-serif;
		font-size: 1.8rem;
		margin-top: 4rem;
		cursor: pointer;

		${breakup.medium`
			padding: 0 4rem;
			height: 4rem;
		`}

		&::after {
			background: #fff !important; 
		}

		&::before {
			background: linear-gradient(to right,transparent,#786ace,transparent) !important;
		}
	`
);


export const PrimaryExt = styled.a(
	(props) => css`
		${sharedStyles}

		height: 4.8rem;
	`
);

export const SubmitJacket = styled.button(
	(props) => css`
		${sharedStyles}

		height: 4.8rem;
		cursor: pointer;
	`
);

export const Secondary = styled(TransitionLink)(
	(props) => css`
		${sharedStyles}

		height: 4rem;
	`
);

export const SecondaryExt = styled.a(
	(props) => css`
		${sharedStyles}

		height: 4rem;
	`
);
