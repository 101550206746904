// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = (props) => css`
	font-weight: ${props.theme.extrabold};
	font-family: ${props.theme.heading};

	${props.weight === 'light' && ` font-weight: ${props.theme.light}; `}
	${props.weight === 'reg' && ` font-weight: ${props.theme.reg}; `}
    ${props.weight === 'med' && ` font-weight: ${props.theme.med}; `}
    ${props.weight === 'semi' && ` font-weight: ${props.theme.semi}; `}
    ${props.weight === 'bold' && ` font-weight: ${props.theme.bold}; `}
    ${props.weight === 'extra' && ` font-weight: ${props.theme.extrabold}; `}
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = (props) => css`
	${sharedHeadingStyles}
	font-size: 3.6rem;
	line-height: 1.15em;
	font-weight: 600;

	${breakpointUp.large`
        font-size: 6rem;
        letter-spacing: -2px;
    `}

	${breakpointUp.xlarge`
        font-size: 7rem;
        letter-spacing: -2px;
    `}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 3.4rem;
	font-weight: 400;
	line-height: 1.15em;

	${breakpointUp.large`
        font-size: 4.8rem;
        letter-spacing: -1px;
    `}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 2.8rem;
	line-height: 3.6rem;
	font-weight: 400;

	${breakpointUp.large`
        font-size: 3rem;
        line-height: 4rem;
    `}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;

	${breakpointUp.large`
        font-size: 1.8rem;
        line-height: 3.2rem;
    `}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 1.6rem;
	line-height: 2.4rem;
	${breakpointUp.large` font-size: 1.8rem; `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = (props) => css`
	font-family: ${props.theme.body};
	font-weight: ${props.theme.reg};

	${props.weight === 'light' && ` font-weight: ${props.theme.light}; `}
	${props.weight === 'reg' && ` font-weight: ${props.theme.reg}; `}
    ${props.weight === 'med' && ` font-weight: ${props.theme.med}; `}
    ${props.weight === 'semi' && ` font-weight: ${props.theme.semi}; `}
    ${props.weight === 'bold' && ` font-weight: ${props.theme.bold}; `}
    ${props.weight === 'extra' && ` font-weight: ${props.theme.extrabold}; `}
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
	font-size: 1.6rem;
	line-height: 1.5em;

	${breakpointUp.large`
		font-size: 2.2rem;
		line-height: 3rem;
		font-weight: ${props.theme.light};
	`}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
`;

export const labelStyles = css`
	padding: 0.35rem 3.5rem;
	font-family: 'Kanit';
	font-weight: 400;
	margin: 0 auto 2.2rem auto;
	max-width: max-content;
	border-radius: 2rem;
	text-transform: uppercase;
	background-color: #0e0e0f;

	${breakpointUp.medium`
		padding: 1rem 4rem;
	`}
`;

export const gradientTextStyles = (props) => css`
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	width: max-content;
	font-size: 1.8rem;
	line-height: 2.4rem;
	font-family: ${props.theme.heading};
	font-weight: ${props.theme.regular};
	background: -webkit-linear-gradient(#3F1FFF, #6C53FF);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1rem;

	${breakpointUp.medium`
		font-size: 1.8rem;
	`}

	${breakpointUp.large`
		line-height: 2.5rem;
		letter-spacing: 1px;
	`}

	&:after {
		content: attr(data-name);
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;

		color: transparent;

		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(${props.theme.bc1}),
			to(${props.theme.bc1}),
			color-stop(0.5, #fff)
		);
		background: -moz-gradient(
			linear,
			left top,
			right top,
			from(${props.theme.bc1}),
			to(${props.theme.bc1}),
			color-stop(0.5, #fff)
		);
		background: gradient(
			linear,
			left top,
			right top,
			from(${props.theme.bc1}),
			to(${props.theme.bc1}),
			color-stop(0.5, #fff)
		);

		background-size: 5rem 100%;

		${breakpointUp.medium`
			background-size: 12rem 100%;
		`}

		-webkit-background-clip: text;
		-moz-background-clip: text;
		background-clip: text;

		@keyframes shimmer {
			from {
				background-position: -100%;
			}

			to {
				background-position: 200%;
			}
		}

		animation-name: shimmer;
		animation-duration: 2.25s;
		animation-iteration-count: infinite;

		background-repeat: no-repeat;
		background-position: 0 0;
	}
`;

export const emStyles = (props) => css`
	display: block;
	font-style: normal;
	font-size: 1.2rem;
	line-height: 1.4rem;
	text-transform: uppercase;
	font-family: ${props.theme.heading};
	font-weight: ${props.theme.bold};

	${breakpointUp.large`
        font-size: 1.4rem;
        line-height: 1.6rem;
    `}
`;
