// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket,
} from './styles';

// Component
// ------
const Logo = () => (
    <Jacket width="51" height="59" viewBox="0 0 51 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.5926 48.4406C49.6124 48.9545 49.4841 49.4626 49.2248 49.898C48.9688 50.2974 48.5814 50.5821 48.1377 50.6968L50 53.9902H48.0609L46.4313 50.9131H44.7157V54H43V46H46.9272H47.0785H47.1506C47.1888 46 47.2269 46.003 47.2653 46.0061C47.3043 46.0092 47.3436 46.0123 47.3834 46.0123C47.6693 46.0276 47.9527 46.0762 48.2285 46.1573C48.5133 46.2412 48.7725 46.4022 48.9804 46.6243C49.1727 46.8196 49.3221 47.057 49.418 47.3198C49.5117 47.5794 49.5705 47.8516 49.5926 48.1284V48.2833V48.4406ZM46.9248 49.372C47.1869 49.3638 47.4461 49.3124 47.693 49.2197C47.9258 49.1263 48.0236 48.9026 48.0236 48.4725C48.0236 48.0424 47.9026 47.7942 47.6488 47.7106C47.3467 47.6158 47.0328 47.5694 46.7177 47.573H46.6385V47.5607H44.8833V49.372H46.7456H46.7619H46.9248Z" />
        <path d="M42.804 46H40.6041L38.9665 48.4086L37.3185 46H35.0954L37.9246 49.9988L35 54H37.2515L39.0335 51.3456L40.7176 54H43L40.1012 49.8906L42.804 46Z" />
        <path d="M19 0V44L51 36.4334V10.3167L19 0Z" />
        <path d="M0 48.0003L10 52V20L0 22.6657V48.0003Z" />
        <path d="M28 59V49L12 52.9995L28 59Z" />
    </Jacket>
);

export default Logo;
