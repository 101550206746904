// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Field({ placeholder, onClick, onChange, className, value }) {
	return (
		<Jacket className={className} isActive={value.length >= 1}>
			<input
				type='text'
				placeholder={placeholder}
				onChange={onChange}
				onClick={onClick}
				value={value}
			/>
			<span>{placeholder}</span>
		</Jacket>
	);
}

export default Field;
