// Imports
// ------
import styled, { css } from 'styled-components';
import { Header, breakup } from '@tackl';
// Exports
// ------
export const Jacket = styled(Header)(
	(props) => css`
		position: fixed;
		z-index: 12;
		top: ${props.notify ? props.notify + `px` : 0};
		left: 0;
		right: 0;

		${breakup.xxlarge`
			width: 100%;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		`}

		.headroom-wrapper {
			position: relative;
			display: flex;
			width: 100%;
			height: auto !important;
		}

		.headroom {
			width: 100%;
			display: flex;
			justify-content: center;
			transition: all 0.2s linear;
			will-change: transform;

			&--unpinned,
			&--scrolled {
				transform: translateY(-150%);
				background: ${props.theme.black};
			}

			&--pinned {
				transform: translateY(-${props.notify}px);
			}
		}
	`
);

export const MobileJacket = styled(Header)(
	(props) => css`
		.headroom-wrapper {
			width: 100%;
			height: auto !important;
		}

		.headroom {
			transition: ${props.theme.ease};

			&--unpinned,
			&--scrolled {
				> div {
					transform: translateY(
						-${props.notifyActive ? props.notificationSize : 0}px
					);
				}
			}
		}
	`
);
