// Imports
// ------
import React from 'react';
import Icon from '@icons';
import { StaticQuery, graphql } from 'gatsby';

// Styles
// ------
import { Jacket, SocialIcon, Link } from './styles';

// Component
// ------
function Social({ center, small }) {
	return (
		<StaticQuery
			query={graphql`
				query PostFooter {
					social: datoCmsOptionsGlobal {
						facebook: socialFacebook
						twitter: socialTwitter
						linkedin: socialLinkedin
						spotify: socialSpotify
						instagram: socialInstagram
						vimeo: socialVimeo
						discord: socialDiscord
						sketchfab: socialSketchfab
						artstation: socialArtstation
					}
				}
			`}
			render={(d) => {
				const socialChannels = [
					{
						url: d.social.twitter,
						label: 'Twitter',
					},
					{
						url: d.social.linkedin,
						label: 'LinkedIn',
					},
					{
						url: d.social.instagram,
						label: 'Instagram',
					},
					{
						url: d.social.artstation,
						label: 'Artstation',
					},
					{
						url: d.social.discord,
						label: 'Discord',
					},
					{
						url: d.social.sketchfab,
						label: 'Sketchfab',
					},
					{
						url: d.social.vimeo,
						label: 'Vimeo',
					},
				];

				return (
					<Jacket center={center} small={small}>
						{socialChannels.map(({ url, label }) => (
							<React.Fragment key={label}>
								{url && (
									<SocialIcon>
										<Link
											href={url}
											target='_blank'
											aria-label={`Go to ${label}`}
											rel='noopener noreferrer'>
											<Icon type={label.toLowerCase()} />
										</Link>
									</SocialIcon>
								)}
							</React.Fragment>
						))}
					</Jacket>
				);
			}}
		/>
	);
}

export default React.memo(Social);
