// Imports
// ------
import React, { useState } from 'react';
import { Row, Column } from '@waffl';
import { useHotkeys } from 'react-hotkeys-hook';

// Styles
// ------
import { Jacket, Fill } from './styles';

// Component
// ------
const Grid = () => {
    const [grid, setGrid] = useState(false);
    const [altColor, setAltColor] = useState(true);
    useHotkeys('ctrl+g', () => { setGrid(grid => !grid) });
    useHotkeys('ctrl+f', () => { setAltColor(altColor => !altColor) });

    return (
        <Jacket mpad showGrid={grid} altColor={altColor} >
            <Row isExpanded equal stretch sidePad>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
                <Column><Fill className="fill" /></Column>
            </Row>
        </Jacket>
    );
}

export default Grid;