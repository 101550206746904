// Imports
// ------
import { css } from "styled-components";
import { breakup } from '@tackl';
import { Column } from './grid';

// Exports
// ------
const collapsed = props => css`
    /* Is Collapsed */
    ${props.isCollapsed && css`
        > ${Column} { padding: 0; }
    `}

    ${props.isCollapsedOnSmedium && css`
        > ${Column} {
            ${breakup.smedium` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnMedium && css`
        > ${Column} {
            ${breakup.medium` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnLarge && css`
        > ${Column} {
            ${breakup.large` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnXlarge && css`
        > ${Column} {
            ${breakup.xlarge` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnXxlarge && css`
        > ${Column} {
            ${breakup.xxlarge` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnHuge && css`
        > ${Column} {
            ${breakup.huge` padding: 0; `}
        }
    `}

    ${props.isCollapsedOnUber && css`
        > ${Column} {
            ${breakup.uber` padding: 0; `}
        }
    `}


    /* Not Collapsed */
    ${props.isNotCollapsedOnSmedium && css`
        > ${Column} {
            ${breakup.smedium`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnMedium && css`
        > ${Column} {
            ${breakup.medium`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnLarge && css`
        > ${Column} {
            ${breakup.large`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnXlarge && css`
        > ${Column} {
            ${breakup.xlarge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnXxlarge && css`
        > ${Column} {
            ${breakup.xxlarge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnHuge && css`
        > ${Column} {
            ${breakup.huge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}

    ${props.isNotCollapsedOnUber && css`
        > ${Column} {
            ${breakup.uber`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
        }
    `}
`;

export default collapsed;