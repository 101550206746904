// Imports
// ------
import { observable } from 'mobx';

// Exports
export const headerState = observable({
	isOpen: false,
	headerSize: 0,
	notifySize: 0,
	notifyActive: true,
	modalActive: false,
});
