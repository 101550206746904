// Imports
// ------
import React, { useEffect, useState } from 'react';
import Logo from '@parts/Logo';
import Icon from '@icons';
import Social from '@parts/Social';
import Copyright from '@parts/Copyright';
import Body from '@utils/bodylock';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { headerState } from '@states/header';

// Styles
// ------
import {
	Jacket,
	Home,
	MenuToggle,
	Menu,
	Page,
	FakePage,
	ListItem,
	Footer,
} from './styles';

// Component
// ------
function Mobile({ notify, nftEnabled }) {
	const [subMenu, setSubMenu] = useState(false);
	const [needsBg, setNeedsBg] = useState(false);

	let timer;

	const handleMenu = () => {
		headerState.isOpen = !headerState.isOpen;

		if (headerState.isOpen) {
			Body.lock();
		} else {
			Body.unlock();
		}
	};

	const handleSubMenu = () => {
		setSubMenu(!subMenu);
	};

	const handleClick = () => {
		timer = setTimeout(() => {
			headerState.isOpen = false;

			Body.unlock();
		}, 500);
	};

	const handleUpdates = (e) => {
		e.preventDefault();

		const activateModal = action(() => {
			headerState.modalActive = true;
		});

		activateModal();
	};

	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		window.onscroll = function (e) {
			if (window.scrollY >= 40) {
				setNeedsBg(true);
			} else {
				setNeedsBg(false);
			}
		};
	}, []);

	return (
		<>
			<Jacket
				needsBg={needsBg}
				top={notify}
				isOpen={headerState.isOpen}
				hasNotification={headerState.notifyActive}>
				<Home
					to='/'
					className={notify ? `` : null}
					entry={{ length: 1 }}
					exit={{ length: 1 }}
					onClick={handleClick}>
					<Logo />
				</Home>

				<MenuToggle isOpen={headerState.isOpen} onClick={handleMenu}>
					<span />
				</MenuToggle>
			</Jacket>

			<Menu isOpen={headerState.isOpen}>
				<ul>

					<ListItem onClick={handleUpdates}>
						<FakePage className="in-progress">
							<span>Marso</span>
						</FakePage>
					</ListItem>

					{nftEnabled && (
						<ListItem>
							<Page
								to='/nft/'
								entry={{ length: 1 }}
								exit={{ length: 1 }}
								onClick={handleClick}>
								NFT
							</Page>
						</ListItem>
					)}

					<ListItem>
						<Page
							to='/material-library/'
							entry={{ length: 1 }}
							exit={{ length: 1 }}
							onClick={handleClick}>
							Library
						</Page>
					</ListItem>

					<ListItem>
						<Page
							to='/blog/'
							entry={{ length: 1 }}
							exit={{ length: 1 }}
							onClick={handleClick}>
							Blog
						</Page>
					</ListItem>

					<ListItem>
						<Page
							to='/careers/'
							entry={{ length: 1 }}
							exit={{ length: 1 }}
							onClick={handleClick}>
							Careers
						</Page>
					</ListItem>

					<ListItem onClick={handleUpdates}>
						<FakePage>
							<span>Updates</span>
						</FakePage>
					</ListItem>

				</ul>

				<Footer>
					<Social center />
					<Copyright />
				</Footer>
			</Menu>
		</>
	);
}

export default observer(Mobile);
