// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket,
} from './styles';

// Component
// ------
const Copyright = () => {
    const date = new Date();
    const year = date.getFullYear();

    return <Jacket weight="light">{year}, M-XR Limited, all rights reserved.</Jacket>;
}

export default Copyright;
