// Imports
// ------
import { css } from "styled-components";
import { breakup } from '@tackl';

// Exports
// ------
const ordering = props => css`
    ${props.order && css`
        order: ${props.order};
    `}

    ${props.orderOnSmall && css`
        order: ${props.orderOnSmall};
    `}

    ${props.orderOnSmedium && css`
        ${breakup.smedium`
            order: ${props.orderOnSmedium};
        `}
    `}

    ${props.orderOnMedium && css`
        ${breakup.medium`
            order: ${props.orderOnMedium};
        `}
    `}

    ${props.orderOnLarge && css`
        ${breakup.large`
            order: ${props.orderOnLarge};
        `}
    `}

    ${props.orderOnXLarge && css`
        ${breakup.xlarge`
            order: ${props.orderOnXLarge};
        `}
    `}

    ${props.orderOnXXLarge && css`
        ${breakup.xxlarge`
            order: ${props.orderOnXXLarge};
        `}
    `}

    ${props.orderOnHuge && css`
        ${breakup.huge`
            order: ${props.orderOnHuge};
        `}
    `}

    ${props.orderOnUber && css`
        ${breakup.uber`
            order: ${props.orderOnUber};
        `}
    `}
`;

export default ordering;