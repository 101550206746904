// Imports
// ------
import { css } from "styled-components";

// Exports
// --------------
// 1. Image Styles
// --------------
export const imageStyles = props => css`
    display: block;
    width: 100%;
    height: auto;

    ${props.fit && css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    `}
`;