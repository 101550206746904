// Imports
// ------
import React, { useEffect } from 'react';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';

// Styles
// ------
import { Jacket, ButtonExt, ButtonInt } from './styles';

// Component
// ------
const InnerContent = ({ label }) => (
	<>
		<span>Update:</span>
		<span>{label}</span>
	</>
);

const Notification = ({ forwardRef, link, label, isExternal }) => {
	useEffect(() => {
		const setNotifySize = action(() => {
			headerState.notifySize = forwardRef.current.offsetHeight;
		});

		setNotifySize();
	}, [headerState.isActive]);

	return (
		<Jacket ref={forwardRef} isVisible={headerState.notifyActive}>
			<motion.div
				initial={{ opacity: 0, y: `-50%` }}
				animate={{ opacity: 1, y: `0%` }}
				transition={{ duration: 1, delay: 0.25 }}>
				{isExternal ? (
					<ButtonInt to={link}>
						<InnerContent label={label} />
					</ButtonInt>
				) : (
					<ButtonExt
						href={link}
						aria-label={label}
						rel='noopener noreferrer'
						target='_blank'>
						<InnerContent label={label} />
					</ButtonExt>
				)}
			</motion.div>
		</Jacket>
	);
};

export default observer(Notification);
