// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.ul(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: ${props.center ? `center` : `flex-start`};
		margin: 0 0 1.2rem 0;
	`
);

export const SocialIcon = styled.li((props) => css``);

export const Link = styled.a(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 1.2rem;

		&:hover svg {
			${breakup.large`
            fill: ${props.theme.bc1};
        `}
		}

		svg {
			fill: ${props.theme.white};
			width: 1.8rem;
			height: 1.8rem;
			transition: ${props.theme.ease};

			${breakup.large`
                width: 2rem;
                height: 2rem;
            `}
		}
	`
);
