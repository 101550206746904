// Imports
// ------
import styled, { css } from 'styled-components';

// Exports
// ------
export const Jacket = styled.svg(props => css`
    fill: ${props.theme.white};
    width: 5.1rem;
    height: 5.9rem;
`);
